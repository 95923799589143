@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

.MuiPopover-root.MuiMenu-root.MuiModal-root .MuiPaper-root .MuiButtonBase-root.Mui-disabled {
	display: none;
}

.x-spreadsheet-bottombar .x-spreadsheet-menu li:first-child .x-spreadsheet-icon {
	display: none;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* height */
::-webkit-scrollbar {
	height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #eee;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #ccc;
	border-radius: 10px;
}

.ReactModal__Content {
	width: 100% !important;
	max-width: 800px !important;
}

.ReactModalPortal .ReactModal__Content .ab-modal-container .ab-modal-iframe {
	margin-top: -100px;
	margin-bottom: -152px;
	height: 100vh;
	overflow: hidden;
}

.pac-container {
	z-index: 2000 !important;
}

.gmap {
	width: 100%;
}

.gmap-mini {
	width: 100%;
	min-height: 100px;
	max-height: 100px;
	border-radius: 0.375rem;
	filter: grayscale(1);
}

.gmap .gm-style div:last-child .gmnoprint,
.gmap .gm-style div:last-child .gm-style-cc,
/* .gmap .gm-style div:nth-last-child(3) > div, */
.gmap-mini .gm-style div:last-child .gmnoprint,
.gmap-mini .gm-style div:last-child .gm-style-cc,
.gmap-mini .gm-style div:nth-last-child(3) > div a {
	display: none !important;
}

@keyframes pulse-animation {
	0% {
		box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
	}
	100% {
		box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
	}
}

.cursor-pointer {
	cursor: pointer!important;
}